<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">

            <v-row class="auth-row ma-0">
                <v-col lg="7"
                       class="d-none d-lg-block position-relative overflow-hidden pa-0">
                    <div class="auth-illustrator-wrapper login-bg">
                        <div class="logo-size">
                            <img class="logo"
                                 :src="require(`@/assets/images/logo-designs/Reverse/Digital/myboat-logo-reverse-rgb.svg`)">
                        </div>

                    </div>
                </v-col>

                <v-col lg="5"
                       class="auth-bg pa-10 pb-0">
                    <v-row style="justify-content: end;">

                        <app-bar-i18n></app-bar-i18n>

                    </v-row>
                    <v-row class="pt-16">
                        <v-col cols="12"
                               sm="8"
                               md="6"
                               lg="12"
                               class="mx-auto">
                            <v-form ref="loginForm"
                                    class="multi-col-validation"
                                    lazy-validation
                                    v-model="valid">
                                <v-card flat>
                                    <v-card-text class="d-flex align-start">
                                        <v-icon class="arabic-arrow"
                                                color="green-darken-2"
                                                style="font-size: 31px;cursor: pointer;"
                                                @click="back">
                                            mdi-arrow-left
                                        </v-icon>
                                        <div>
                                            <p class="text-2xl text-center text--primary mb-2">

                                                <span class="font-weight-light"> {{ $t('Forgot') }}</span> <span
                                                      class="font-weight-bold">{{ $t('Password?') }}</span>
                                                <!-- 👋🏻 -->
                                            </p>
                                            <p class="mb-2 mt-6 text-center">
                                                {{ $t('Please enter your Email to reset your password') }}<br>


                                            </p>
                                        </div>

                                    </v-card-text>

                                    <!-- login form -->
                                    <v-card-text>
                                        <p class="font-weight-semibold text-black mb-2">
                                            {{ $t('Email') }}
                                        </p>
                                        <v-text-field v-model="email"
                                                      @keyup.enter="resetPassword"
                                                      outlined
                                                      placeholder="Email"
                                                      prepend-inner-icon="mdi-account"
                                                      :rules="emailRule"
                                                      hide-details="auto"
                                                      class="mb-4">
                                            <template #message="{ message }">
                                                {{ $t(message) }}
                                            </template>
                                        </v-text-field>

                                        <v-btn block
                                               color="primary"
                                               :disabled="!valid"
                                               @click="resetPassword"
                                               class="text-lower">
                                            {{ $t(' Next') }}
                                        </v-btn>
                                    </v-card-text>
                                </v-card>
                            </v-form>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import router from '@/router'
import userServices from '@/services/user-services'
import AppBarI18n from '@core/layouts/components/app-bar/AppBarI18n.vue'
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'
import { ref } from '@vue/composition-api'
export default {
    components: {
        AppBarI18n
    },
    data: () => ({
        valid: false,
        emailRule: [
            v => !!v || 'Email is required.',
            v => /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(v) || 'Email is required.'
        ],
    }),
    methods: {
        back()
        {
            window.history.back();
        },
        resetPassword()
        {

            this.$refs.loginForm.validate()
            if (this.$refs.loginForm.validate())
            {
                let object = {
                    username: this.email
                }
                userServices.resetPassword(object, 'generate-otp').then(res =>
                {
                    if (res.data.statusCode == 200)
                    {
                        // let otp = res.data.otp.otp;
                        localStorage.setItem('email', this.email)
                        router.push({ name: 'one-time-passcode', params: { type: 'forgot' } });
                    }
                    else if (res.data.statusCode == 404)
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Error',
                            confirmButtonColor: '#00b2a9',
                            text: 'User not found.',
                        })
                    }
                    else
                    {
                        this.$swal({
                            icon: 'error',
                            title: 'Error',
                            confirmButtonColor: '#00b2a9',
                            text: 'User not found.',
                        })
                    }
                }).catch(error =>
                {
                    this.$swal({
                        icon: 'error',
                        title: 'Error',
                        confirmButtonColor: '#00b2a9',
                        text: 'User not found.',
                    })
                });
            }
        }
    },
    setup()
    {
        const isPasswordVisible = ref(false)
        const email = ref('')

        return {
            isPasswordVisible,
            email,

            // themeConfig
            appName: themeConfig.app.name,
            appLogo: themeConfig.app.logo,

            icons: {
                mdiChevronLeft,
            },
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/auth.scss';

::v-deep .v-text-field input {
    padding: 8px 8px 8px;
}

::v-deep .v-input .v-input__prepend-inner .v-input__icon .v-icon,
.v-input .v-input__append-inner .v-input__icon .v-icon {
    transform-origin: 10px;
    color: #00B2A9 !important;
}

// ::v-deep .v-input__icon .v-icon {
//     color: #00B2A9 !important;
// }
</style>
